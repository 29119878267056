import React from 'react';
import OwlCarousel from 'react-owl-carousel2';
import SectionTitle from '../../components/Common/SectionTitle';
import SingleTestimonial from '../../components/Testimonial/SingleTestimonial';

// import quote from '../../assets/img/testimonial/quote-white.png';
import quote from '../../assets/img/testimonial/quote-orange.png';
import author1 from '../../assets/img/testimonial/1.jpg';
import author2 from '../../assets/img/testimonial/2.jpg';
import author3 from '../../assets/img/testimonial/3.jpg';
import author4 from '../../assets/img/testimonial/4.jpg';
import author5 from '../../assets/img/testimonial/5.jpg';

const Testimonial = () => {
    const options = {
        items: 2,
        nav: false,
        dots: true,
        margin: 30,
        rewind: false,
        autoplay: false,
        stagePadding: 30,
        loop: true,
        center: false,
        responsive: {
            0: {
                stagePadding: 0,
                items: 1,
                dots: false,
            },
            768: {
                items: 1,
                stagePadding: 0,
                dots: true,
            },
            992: {
                items: 1,
                stagePadding: 0,
            },
            1200: {
                items: 2,
                stagePadding: 0,
            },
            1500: {
                items: 2,
                stagePadding: 0,
            },
        },
    };

    return (
        <React.Fragment>
            <div className="rs-testimonial main-home bg18 style2 pt-120 pb-120 md-pt-80 md-pb-80">
                <div className="container">
                    <SectionTitle
                        sectionClass="sec-title2 text-center mb-46"
                        subtitleClass="sub-text white-color"
                        subtitle="Testimonial"
                        titleClass="title testi-title white-color"
                        title=" What Saying Our Customers"
                    />
                    <OwlCarousel options={options}>
                        <SingleTestimonial
                            itemClass="testi-item"
                            quoteImage={quote}
                            authorImage={author1}
                            Title="David Warner"
                            Designation="Web Developer"
                            // Description="Neque porro quisquam est, qui dolorem ipsum quia dolor sit amet, consectetur, adipisci velit, sed quia non numquam eius modi tempora incidunt Neque porro quisquam est, qui dolorem
                            // ipsum..."
                            Description="I have collaborated with this company many times, and their exceptional service providers and unparalleled creativity have transformed my projects into outstanding successes."
                        />
                        {/* <SingleTestimonial
                            itemClass="testi-item"
                            quoteImage={quote}
                            authorImage={author2}
                            Title="Mitchel Starc"
                            Designation="App Developer"
                            Description="Neque porro quisquam est, qui dolorem ipsum quia dolor sit amet, consectetur, adipisci velit, sed quia non numquam eius modi tempora incidunt Neque porro quisquam est, qui dolorem
                            ipsum..."
                        /> */}
                        {/* <SingleTestimonial
                            itemClass="testi-item"
                            quoteImage={quote}
                            authorImage={author3}
                            Title="Steve Smith"
                            Designation="Web Designer"
                            // Description="Neque porro quisquam est, qui dolorem ipsum quia dolor sit amet, consectetur, adipisci velit, sed quia non numquam eius modi tempora incidunt Neque porro quisquam est, qui dolorem
                            // ipsum..."
                            Description="Working with this company as a web designer has been a pleasure. Their understanding of design principles and attention to detail have impressed me. They facilitate a supportive environment that enables me to deliver visually stunning, user-friendly websites."
                        /> */}
                        <SingleTestimonial
                            itemClass="testi-item"
                            quoteImage={quote}
                            authorImage={author4}
                            Title="Bret Lee"
                            Designation="Client Manager"
                            // Description="Neque porro quisquam est, qui dolorem ipsum quia dolor sit amet, consectetur, adipisci velit, sed quia non numquam eius modi tempora incidunt Neque porro quisquam est, qui dolorem
                            // ipsum..."
                            Description="I have worked with this company for several projects, and their team is devoted, skilled, and efficient in delivering remarkable solutions that consistently exceed client expectations."
                        />
                        <SingleTestimonial
                            itemClass="testi-item"
                            quoteImage={quote}
                            authorImage={author5}
                            Title="Shan Warne"
                            Designation="Digital Marketar"
                            // Description="Neque porro quisquam est, qui dolorem ipsum quia dolor sit amet, consectetur, adipisci velit, sed quia non numquam eius modi tempora incidunt Neque porro quisquam est, qui dolorem
                            // ipsum..."
                            Description="I have worked with many companies, but none have impressed me as much as this one. Their remarkable expertise and unwavering dedication have consistently driven impressive results for my campaigns."
                        />
                    </OwlCarousel>
                </div>
            </div>
        </React.Fragment>
    );
};

export default Testimonial;

import React from 'react';

import Shape from '../../assets/img/about/custom/shape.png';
import OrangeShapes from '../../assets/img/about/custom/orange-shapes.png';

const SingleProcess = (props) => {
	return(
        <div className={props.itemClass}>
            <div className="process-img">
                <img className='outer-wrapper-image about-process-images' style={{animationDelay: `${props.delay}s`}} src={OrangeShapes} alt='Code Horizon' />
                <img className='inner-wrapper-image about-process-images' src={Shape} alt='Code Horizon' />
                <img
                    src={props.processImage}
                    alt={props.Title}
                    className='wrappers-main-image about-process-images'
                />
            </div>
            <div className="process-text">
                <h3 className={props.titleClass}>{props.Title}</h3>
            </div>
        </div>
	)
}

export default SingleProcess
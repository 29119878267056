// React hooks and functionality
import React, { useRef, useState } from 'react';

// EmailJs Service
import emailjs from '@emailjs/browser';

// Notifications
import { toast } from 'react-toastify';

const ContactForm = (props) => {
    const { submitBtnClass } = props;

    const formRef = useRef();

    const [isSending, setIsSending] = useState(false);

    const serviceID = process.env.REACT_APP_EMAILJS_SERVICE_ID;
    const templateID = process.env.REACT_APP_EMAILJS_TEMPLATE_ID;
    const publicKey = process.env.REACT_APP_EMAILJS_PUBLIC_KEY;

    const handleFormSubmit = (event) => {
        event.preventDefault();
        setIsSending(true);
        emailjs.sendForm(serviceID, templateID, formRef.current, publicKey)
            .then((result) => {
                // show the user a success message
                setIsSending(false);
                toast.success('Your message was delivered. Thanks for contacting us!');
                formRef.current.reset();
            }, (error) => {
                // show the user an error
                setIsSending(false);
                toast.error('Your message could not be sent. Please try again.');
            });
    }

    return (
        <form ref={formRef} id="contact-form" action="#" onSubmit={handleFormSubmit}>
            <div className="row">
                <div className="col-md-6 mb-30">
                    <input className="from-control" type="text" id="name" name="clientName" placeholder="Name" required />
                </div>

                <div className="col-md-6 mb-30">
                    <input className="from-control" type="text" id="email" name="clientEmail" placeholder="E-Mail" required />
                </div>

                <div className="col-md-6 mb-30">
                    <input className="from-control" type="text" id="phone" name="clientPhone" placeholder="Phone Number" required />
                </div>

                <div className="col-md-6 mb-30">
                    <input className="from-control" type="text" id="website" name="clientWebsite" placeholder="Your Website" required />
                </div>

                <div className="col-12 mb-30">
                    <textarea className="from-control" id="message" name="clientMessage" placeholder="Your message Here" required></textarea>
                </div>
            </div>
            <div className="btn-part">
                <button className={submitBtnClass ? submitBtnClass : 'readon learn-more submit'} type="submit">Submit Now</button>
            </div>
        </form>
    );

}

export default ContactForm;
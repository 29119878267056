import React from 'react';
import { Link } from 'react-router-dom';
import SectionTitle from '../../components/Common/SectionTitle';
import SingleProcess from '../../components/Common/Process';

const Service = () => {

    return (
        // <div className="rs-process pt-180 pb-120 md-pt-70 md-pb-70">
        <div className="rs-process pt-120 pb-120 md-pt-70 md-pb-70">
            <div className="container">
                <div className="row align-items-stretch">
                    <div className="col-lg-4 md-mb-40">
                        <div className="process-wrap h-100 ">
                            <div className="content-wrapper h-100 d-flex flex-column justify-content-center">
                                <SectionTitle
                                    sectionClass="sec-title mb-30"
                                    subtitleClass="sub-text new"
                                    subtitle="Working Process"
                                    titleClass="title white-color"
                                    title="Our Working Process -  How We Work For Our Customers"
                                />
                                <div className="btn-part mt-40">
                                    <Link to="/contact" className="readon learn-more contact-us">Contact Us</Link>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-lg-8 pl-30 md-pl-15">
                        <div className="row">
                            <div className="col-md-6 mb-40">
                                <SingleProcess 
                                    processNumber="1"
                                    // processTitle="Discovery"
                                    processTitle="Analysis"
                                    // processText="Quisque placerat vitae lacus ut scelerisque. Fusce luctus odio ac nibh luctus, in porttitor theo lacus egestas."
                                    processText="Discover your business needs and objectives, and let us form a plan customized to meet those
                                    goals. Our experts will examine your requirements and create a solution that perfectly fits your
                                    business, setting the foundation to establish a successful and detailed project schedule and
                                    setting milestones."
                                />                                
                            </div>
                            <div className="col-md-6 mb-40">                                
                                <SingleProcess 
                                    processNumber="2"
                                    // processTitle="Planning"
                                    processTitle="Execution"
                                    // processText="Quisque placerat vitae lacus ut scelerisque. Fusce luctus odio ac nibh luctus, in porttitor theo lacus egestas."
                                    processText="Our talented professionals will bring the plan to life, developing your software solutions with top-
                                    tier code. To ensure that the product meets industry standards for both quality and
                                    implementation as we work to deliver efficient and reliable solutions."
                                />
                            </div>
                            <div className="col-md-6 sm-mb-40">
                                <SingleProcess 
                                    processNumber="3"
                                    // processTitle="Execute"
                                    processTitle="Testing"
                                    // processText="Quisque placerat vitae lacus ut scelerisque. Fusce luctus odio ac nibh luctus, in porttitor theo lacus egestas."
                                    processText="Quality is paramount in our testing phase. Our team conducts comprehensive and rigorous
                                    testing code by Evaluating individual components to ensure your product functions optimally
                                    and meets every necessary requirement. All bugs and issues found will be addressed to
                                    guarantee perfect execution."
                                />
                            </div>
                            <div className="col-md-6">
                                <SingleProcess 
                                    processNumber="4"
                                    // processTitle="Deliver"
                                    processTitle="Deployment"
                                    // processText="Quisque placerat vitae lacus ut scelerisque. Fusce luctus odio ac nibh luctus, in porttitor theo lacus egestas."
                                    processText="We ensure a smooth and effortless transition to production using our deployment expertise. Our
                                    team deploys your solution carefully with full support and maintenance provided to guarantee
                                    client satisfaction. Trust us to handle your IT solutions so you can concentrate on growing your
                                    business."
                                />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Service;
import React from 'react';
import { Link } from 'react-router-dom';
import FooterBottom from './FooterBottom';


import Newsletter from '../../Common/Newsletter';

// import footerLogo1 from '../../../assets/img/logo/logo.png';
import footerLogo1 from '../../../assets/img/logo/code-horizon-logo2.png';
import { ReactComponent as LocationIcon } from '../../../assets/img/main_page/location.svg';
import { ReactComponent as CallIcon } from '../../../assets/img/main_page/call.svg';
import { ReactComponent as EmailIcon } from '../../../assets/img/main_page/email.svg';
import { ReactComponent as ClockIcon } from '../../../assets/img/main_page/Opening Hours.svg';

const Footer = (props) => {
    const { footerLogo, footerClass } = props;
    return (
        <footer className={footerClass ? footerClass : 'rs-footer'}>
            <div className="footer-top">
                <div className="container">
                    <div className="row">
                        <div className="col-xl-3 col-lg-3 col-md-4">
                            <div className="footer-logo">
                                <Link to="/" ><img src={footerLogo ? footerLogo : footerLogo1} alt="Logo" /></Link>
                            </div>
                            <div className="textwidget pb-30">
                                <p>Sedut perspiciatis unde omnis iste natus error sitlutem acc usantium doloremque denounce with illo inventore veritatis</p>
                            </div>
                            <ul className="footer-social md-mb-30">
                                <li>
                                    <a href="https://www.facebook.com/codehorizonservices" target="_blank" rel="noopener"><i className="fa fa-facebook-f"></i></a>
                                </li>
                                {/*<li>*/}
                                {/*    <a href="#"><i className="fa fa-twitter"></i></a>*/}
                                {/*</li>*/}
                                <li>
                                    {/*<a href="#"><i className="fa fa-pinterest"></i></a>*/}
                                    <a href="https://www.linkedin.com/company/codehorizonservices/" target="_blank" rel="noopener"><i className="fa fa-linkedin"></i></a>
                                </li>
                                <li>
                                    <a href="https://instagram.com/codehorizonservices?igshid=NTc4MTIwNjQ2YQ==" target="_blank" rel="noopener"><i className="fa fa-instagram"></i></a>
                                </li>
                            </ul>
                        </div>
                        <div className="col-lg-3 col-md-12 col-sm-12 pl-44 md-pl-14 md-mb-30">
                            <h3 className="widget-title">IT Services</h3>
                            <ul className="site-map">
                                <li><Link to="/service/mobile-app-development">Mobile App Development</Link></li>
                                <li><Link to="/service/Web-development">Web Development</Link></li>
                                <li><Link to="/service/analytic-solutions">Analytic Solutions</Link></li>
                                {/*<li><Link to="/service/cloud-and-devOps">Cloud and DevOps</Link></li>*/}
                                <li><Link to="/service/product-design">Product Design</Link></li>
                                <li><Link to="/service/server-hosting">Server Hosting</Link></li>
                            </ul>
                        </div>
                        <div className="col-lg-3 col-md-12 col-sm-12 md-mb-30">
                            <h3 className="widget-title">Contact Info</h3>
                            <ul className="address-widget">
                                <li>
                                    {/*<i className="flaticon-location"></i>*/}
                                    <LocationIcon className='custom-footer-icons' />
                                    {/*<div className="desc">374 FA Tower, William S Blvd 2721, IL, USA</div>*/}
                                    <div className="desc">Latakia, Syria</div>
                                </li>
                                <li>
                                    {/*<i className="flaticon-call"></i>*/}
                                    <CallIcon className='custom-footer-icons' />
                                    <div className="desc">
                                        {/*<Link to="#">(+880)155-69569</Link>*/}
                                        <a href="tel:+963938983154" target="_blank" rel="noopener">+963 938 983 154</a>
                                    </div>
                                </li>
                                <li>
                                    {/*<i className="flaticon-email"></i>*/}
                                    <EmailIcon className='custom-footer-icons' />
                                    <div className="desc">
                                        {/*<Link to="#">support@rstheme.com</Link>*/}
                                        <a href="mailto:info@codehorizon.org" target="_blank" rel="noopener">info@codehorizon.org</a>
                                    </div>
                                </li>
                                <li>
                                    {/*<i className="flaticon-clock"></i>*/}
                                    <ClockIcon className='custom-footer-icons' />
                                    {/*<div className="desc">*/}
                                    {/*    Opening Hours: 10:00 - 18:00*/}
                                    {/*</div>*/}
                                    <div className="desc">
                                        Opening Hours: 8:00 a.m - 4:00 p.m
                                    </div>
                                </li>
                            </ul>
                        </div>
                        <div className="col-lg-3 col-md-12 col-sm-12">
                            <h3 className="widget-title">Newsletter</h3>
                            <p className="widget-desc">We denounce with righteous and in and dislike men who are so beguiled and demo realized.</p>
                            <p>
                                {/* newsletter-area-start */}
                                <Newsletter />
                                {/* newsletter-area-end */}
                            </p>
                        </div>
                    </div>
                </div>
            </div>
            <FooterBottom />
        </footer>
    );
}

export default Footer;
import React from 'react';
import SingleService from '../../components/Service/SingleService';

import serviceIcon1 from '../../assets/img/service/style1/1.png';
import serviceIcon2 from '../../assets/img/service/style1/2.png';
import serviceIcon3 from '../../assets/img/service/style1/3.png';
import serviceIcon4 from '../../assets/img/service/style1/4.png';
// import serviceIcon1 from '../../assets/img/main_page/1.png';
// import serviceIcon2 from '../../assets/img/main_page/2.png';
// import serviceIcon3 from '../../assets/img/main_page/3.png';
// import serviceIcon4 from '../../assets/img/main_page/4.png';

const ServiceTwo = () => {

    return (
        <div className="rs-services main-home style1 pt-100 md-pt-70">
            <div className="container">
                <div className="row">
                    <div className="col-lg-3 col-md-6 md-mb-50">
                        <SingleService 
                            itemClass="services-item custom-feature-card"
                            serviceImage={serviceIcon1}
                            serviceURL="it-management"
                            // Title="IT Management"
                            Title="Experienced Developers"
                            splitTitle
                            // Text="Quisque placerat vitae lacus ut scelerisque fusce luctus odio ac nibh luctu. "
                            Text="Our team of seasoned developers has years of experience in creating obust software solutions."
                        />
                    </div>
                    <div className="col-lg-3 col-md-6 md-mb-50">
                        <SingleService 
                            itemClass="services-item custom-feature-card"
                            serviceImage={serviceIcon2}
                            serviceURL="cloud-services"
                            // Title="Cloud Services"
                            Title="Tailored Solutions"
                            splitTitle
                            // Text="Quisque placerat vitae lacus ut scelerisque fusce luctus odio ac nibh luctu. "
                            Text="We understand that one size doesn't fit all. We craft custom solutions to meet your unique business needs."
                        />
                    </div>
                    <div className="col-lg-3 col-md-6  md-mb-50">
                        <SingleService 
                            itemClass="services-item custom-feature-card"
                            serviceImage={serviceIcon3}
                            serviceURL="data-security"
                            // Title="Data Security"
                            Title="End-to-End Services"
                            splitTitle
                            // Text="Quisque placerat vitae lacus ut scelerisque fusce luctus odio ac nibh luctu. "
                            Text="From concept to deployment and maintenance, we've got you covered."
                        />
                    </div>
                    <div className="col-lg-3 col-md-6">
                        <SingleService 
                            itemClass="services-item custom-feature-card"
                            serviceImage={serviceIcon4}
                            serviceURL="machine-learning"
                            // Title="Machine Learning"
                            Title="Cutting-Edge Technology"
                            splitTitle
                            // Text="Quisque placerat vitae lacus ut scelerisque fusce luctus odio ac nibh luctu. "
                            Text="We stay ahead of the curve, utilizing the latest technology to deliver top-notch software."
                        />
                    </div>                        
                </div>
            </div>
        </div>
    );
}

export default ServiceTwo;
import React, { useState, useEffect } from 'react';
import { Link, useLocation } from 'react-router-dom';
import TopHeaderStyleTwo from './TopBarStyleTwo';
import RSMobileMenu from './RSMobileMenu';
import MenuItems from './MenuItems';

// import Logo from '../../../assets/img/logo/logo.png';
import Logo from '../../../assets/img/logo/code-horizon-logo2.png';
// import Logo from '../../../assets/img/main_page/logo.svg';

const HeaderStyleTwo = (props) => {
	const { parentMenu, secondParentMenu, activeMenu } = props;

	const location = useLocation();

	const [menuOpen, setMenuOpen] = useState(false);

	const [isVisible, setIsVisible] = useState(false);
	useEffect(() => {
		// Sticky is displayed after scrolling for 100 pixels
		const toggleVisibility = () => {
			if (window.pageYOffset > 100) {
				setIsVisible(true);
			} else {
				setIsVisible(false);
			}
		};

		window.addEventListener("scroll", toggleVisibility);

		return () => window.removeEventListener("scroll", toggleVisibility);
	}, []);

	const searchModalAdd = () => {
		document.body.classList.add('modal-open');
	};

	return (
		<React.Fragment>
			<div className="full-width-header">
				<header id="rs-header" className="rs-header style2">
					{/*<TopHeaderStyleTwo />*/}
					<div className={isVisible ? 'menu-area menu-sticky sticky' : 'menu-area menu-sticky'}>
						<div className="container">
							<div className="row align-items-center">
								<div className="col-lg-3">
									<div className="logo-part hidden-md">
										<Link to="/">
											<img src={Logo} alt="Code Horizon" />
										</Link>
									</div>
								</div>
								<div className="col-lg-9 text-right md-text-left">
									<div className="rs-menu-area">
										<div className="main-menu">
											<div className="mobile-menu style2 md-display-block">
												<Link to="/" className="logo"><img src={Logo} alt="Code Horizon" /></Link>
												<Link to="#" className="rs-menu-toggle primary" onClick={() => {
													setMenuOpen(!menuOpen)
												}}>
													<i className="fa fa-bars"></i>
												</Link>
											</div>
											<nav className="rs-menu pr-100 lg-pr-50 md-pr-0 hidden-md">
												<ul className="nav-menu">
													<MenuItems
														parentMenu={parentMenu}
														secondParentMenu={secondParentMenu}
														activeMenu={activeMenu}
													/>
													{/*<li>*/}
													{/*	<Link to="/" className={location.pathname === "/" || location.pathname === "/home-2" ? "active-menu" : ""}>Home</Link>*/}
													{/*</li>*/}
													{/*<li>*/}
													{/*	<Link to="/about" className={location.pathname === "/about" ? "active-menu" : ""}>About Us</Link>*/}
													{/*</li>*/}
													{/*<li>*/}
													{/*	<Link to="/contact" className={location.pathname === "/contact" ? "active-menu" : ""}>Contact Us</Link>*/}
													{/*</li>*/}
													<li className='custom-header-social-icons'>
														<a href="https://www.facebook.com/codehorizonservices" target="_blank" rel="noopener"><i className="fa fa-facebook-f"></i></a>
													</li>
													{/*<li>*/}
													{/*    <a href="#"><i className="fa fa-twitter"></i></a>*/}
													{/*</li>*/}
													<li className='custom-header-social-icons'>
														{/*<a href="#"><i className="fa fa-pinterest"></i></a>*/}
														<a href="https://www.linkedin.com/company/codehorizonservices/" target="_blank" rel="noopener"><i className="fa fa-linkedin"></i></a>
													</li>
													<li className='custom-header-social-icons'>
														<a href="https://instagram.com/codehorizonservices?igshid=NTc4MTIwNjQ2YQ==" target="_blank" rel="noopener"><i className="fa fa-instagram"></i></a>
													</li>
												</ul>
												{/*<div className="col-lg-4 text-right">*/}
												{/*	<div className="toolbar-sl-share">*/}
												{/*		<ul>*/}
												{/*			/!*<li className="opening"> <em>*!/*/}
												{/*			/!*	<i className="fa fa-clock"></i> 08:00am - 4:00pm</em>*!/*/}
												{/*			/!*</li>*!/*/}
												{/*			<li>*/}
												{/*				<a href="https://www.facebook.com/codehorizonservices" target="_blank" rel="noopener"><i className="fa fa-facebook-f"></i></a>*/}
												{/*			</li>*/}
												{/*			/!*<li>*!/*/}
												{/*			/!*    <a href="#"><i className="fa fa-twitter"></i></a>*!/*/}
												{/*			/!*</li>*!/*/}
												{/*			<li>*/}
												{/*				/!*<a href="#"><i className="fa fa-pinterest"></i></a>*!/*/}
												{/*				<a href="https://www.linkedin.com/company/codehorizonservices/" target="_blank" rel="noopener"><i className="fa fa-linkedin"></i></a>*/}
												{/*			</li>*/}
												{/*			<li>*/}
												{/*				<a href="https://instagram.com/codehorizonservices?igshid=NTc4MTIwNjQ2YQ==" target="_blank" rel="noopener"><i className="fa fa-instagram"></i></a>*/}
												{/*			</li>*/}
												{/*		</ul>*/}
												{/*	</div>*/}
												{/*</div>*/}
											</nav>
										</div>
										{/*<div className="expand-btn-inner search-icon hidden-md">*/}
										{/*	<ul>*/}
										{/*		<li className="sidebarmenu-search">*/}
										{/*			<a href="#" onClick={searchModalAdd} className="rs-search" href="#">*/}
										{/*				<i className="flaticon-search"></i>*/}
										{/*			</a>*/}
										{/*		</li>*/}
										{/*	</ul>*/}
										{/*</div>*/}
									</div>
								</div>
							</div>
						</div>
					</div>

					<RSMobileMenu menuOpen={menuOpen} setMenuOpen={setMenuOpen} />
					<div onClick={() => setMenuOpen(false)} className={menuOpen ? "body-overlay show" : "body-overlay"}></div>

				</header>
			</div>
		</React.Fragment>
	);
}

export default HeaderStyleTwo;
import React from 'react';
import { Link } from 'react-router-dom';
import CTA from '../../components/Common/CTA';
import SectionTitle from '../../components/Common/SectionTitle';
import SingleServiceFlip from '../../components/Service/SingleServiceFlip';
import SingleProcess from '../../components/Common/Process';
import VideoStyleTwo from '../../components/Video/VideoStyleTwo';

// Service Icons
import serviceIcon1 from '../../assets/img/service/main-home/1.png';
import serviceIcon2 from '../../assets/img/service/main-home/2.png';
import serviceIcon3 from '../../assets/img/service/main-home/3.png';
import serviceIcon4 from '../../assets/img/service/main-home/4.png';
import serviceIcon5 from '../../assets/img/service/main-home/5.png';
import serviceIcon6 from '../../assets/img/service/main-home/6.png';

const ServiceOneMain = () => {
    return (
        <React.Fragment>
            {/* services-area-start */}
            <div className="rs-services style2 pt-120 pb-120 md-pt-80 md-pb-80">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-4 col-md-6 mb-26">
                            <SingleServiceFlip
                                itemClass="flip-box-inner"
                                serviceImage={serviceIcon1}
                                Title="Mobile App Development"
                                // TextDesc="We denounce with righteous indignation and dislike men who are so beguiled and demo ralized your data."
                                TextDesc="Empower your business with high-performance mobile apps that connect with your customers."
                                ButtonClass="readon view-more"
                                ButtonText="Get In Touch"
                                ServiceUrl="/service/mobile-app-development"
                            />
                        </div>
                        <div className="col-lg-4 col-md-6 mb-26">
                            <SingleServiceFlip
                                itemClass="flip-box-inner"
                                serviceImage={serviceIcon2}
                                Title="Web Development"
                                // TextDesc="We denounce with righteous indignation and dislike men who are so beguiled and demo ralized your data."
                                TextDesc="Elevate your online presence with captivating websites that leave a lasting impression."
                                ButtonClass="readon view-more"
                                ButtonText="Get In Touch"
                                ServiceUrl="/service/web-development"
                            />
                        </div>
                        <div className="col-lg-4 col-md-6 mb-26">
                            <SingleServiceFlip
                                itemClass="flip-box-inner"
                                serviceImage={serviceIcon3}
                                Title="Analytic Solutions"
                                TextDesc="We guarantee you Streamline data, harness insights, and elevate your online presence."
                                ButtonClass="readon view-more"
                                ButtonText="Get In Touch"
                                ServiceUrl="/service/analytic-solutions"
                            />
                        </div>
                        {/*<div className="col-lg-4 col-md-6 md-mb-26">*/}
                        {/*    <SingleServiceFlip*/}
                        {/*        itemClass="flip-box-inner"*/}
                        {/*        serviceImage={serviceIcon4}*/}
                        {/*        Title="Cloud and DevOps"*/}
                        {/*        TextDesc="We denounce with righteous indignation and dislike men who are so beguiled and demo ralized your data."*/}
                        {/*        ButtonClass="readon view-more"*/}
                        {/*        ButtonText="Get In Touch"*/}
                        {/*    />*/}
                        {/*</div>*/}
                        <div className="col-lg-4 col-md-6 sm-mb-26">
                            <SingleServiceFlip
                                itemClass="flip-box-inner"
                                serviceImage={serviceIcon5}
                                Title="Product Design"
                                TextDesc="Transforming raw ideas into gold with our product and design solutions."
                                ButtonClass="readon view-more"
                                ButtonText="Get In Touch"
                                ServiceUrl="/service/product-design"
                            />
                        </div>
                        <div className="col-lg-4 col-md-6">
                            <SingleServiceFlip
                                itemClass="flip-box-inner"
                                serviceImage={serviceIcon6}
                                Title="Server Hosting"
                                // TextDesc="We denounce with righteous indignation and dislike men who are so beguiled and demo ralized your data."
                                TextDesc="Trust your data to our secure and reliable hosting solutions."
                                ButtonClass="readon view-more"
                                ButtonText="Get In Touch"
                                ServiceUrl="/service/server-hosting"
                            />
                        </div>
                    </div>
                </div>
            </div>
            {/* services-area-end */}

            {/* process-area-start */}
            {/*<div className="rs-process style5 bg5 pt-120 pb-120 md-pt-80 md-pb-64">*/}
            <div className="rs-process style5 bg5">
                <div className='inner-container-wrapper pt-120 pb-120 md-pt-80 md-pb-64'>
                    <div className="container">
                        <div className="row align-items-stretch">
                            <div className="col-lg-4 md-mb-40">
                                <div className="process-wrap h-100">
                                    <div className='content-wrapper h-100 d-flex flex-column justify-content-center '>
                                        <SectionTitle
                                            sectionClass="sec-title mb-30"
                                            subtitleClass="sub-text new"
                                            subtitle="Working Process"
                                            titleClass="title white-color"
                                            title="Our Working Process -  How We Work For Our Customers"
                                        />
                                        <div className="btn-part mt-40">
                                            <Link to="/contact">
                                                <a className="readon learn-more contact-us">Contact Us</a>
                                            </Link>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-8 pl-30 md-pl-15">
                                <div className="row">
                                    <div className="col-md-6 mb-40">
                                        <SingleProcess
                                            processNumber="1"
                                            // processTitle="Discovery"
                                            processTitle="Analysis"
                                            processText="Discover your business needs and objectives, and let us form a plan customized to meet those
                                            goals. Our experts will examine your requirements and create a solution that perfectly fits your
                                            business, setting the foundation to establish a successful and detailed project schedule and
                                            setting milestones."
                                            services
                                        />
                                    </div>
                                    <div className="col-md-6 mb-40">
                                        <SingleProcess
                                            processNumber="2"
                                            // processTitle="Planning"
                                            processTitle="Execution"
                                            processText="Our talented professionals will bring the plan to life, developing your software solutions with top-tier code. To ensure that the product meets industry standards for both quality and
                                            implementation as we work to deliver efficient and reliable solutions."
                                            services
                                        />
                                    </div>
                                    <div className="col-md-6 sm-mb-40">
                                        <SingleProcess
                                            processNumber="3"
                                            // processTitle="Execute"
                                            processTitle="Testing"
                                            processText="Quality is paramount in our testing phase. Our team conducts comprehensive and rigorous
                                            testing code by Evaluating individual components to ensure your product functions optimally
                                            and meets every necessary requirement. All bugs and issues found will be addressed to
                                            guarantee perfect execution."
                                            services
                                        />
                                    </div>
                                    <div className="col-md-6">
                                        <SingleProcess
                                            processNumber="4"
                                            // processTitle="Deliver"
                                            processTitle="Deployment"
                                            processText="We ensure a smooth and effortless transition to production using our deployment expertise. Our
                                            team deploys your solution carefully with full support and maintenance provided to guarantee
                                            client satisfaction. Trust us to handle your IT solutions so you can concentrate on growing your
                                            business."
                                            services
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            {/* process-area-End */}

            {/* Contact Form Start */}
            {/*<VideoStyleTwo />*/}
            {/* Contact Form End */}

            {/* newsletter-area-start */}
            <CTA
                // ctaSectionClass="rs-cta style1 cta-bg1 pt-70 pb-70"
                ctaSectionClass="rs-cta style1 cta-bg1 mt-150"
                ctaTitleClass="epx-title"
                ctaTitle="Grow Your Business and Build Your Website or Software With us."
                ctaButtonClass="readon learn-more"
                ctaButtonLink="#"
                ctaButtonText="Get In Touch"
            />
            {/* newsletter-area-end */}
        </React.Fragment>
    );
}

export default ServiceOneMain;
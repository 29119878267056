import React from 'react';
import { Link } from 'react-router-dom';

const SiteBreadcrumb = (props) => {
	const { pageTitle, titleClass, parentCategory, pageCategory, pageCategoryUrl, pageName, pageDescription, breadcrumbsImg } = props;

	const breadcrumbsImgStyle = {
		backgroundImage: `url(${breadcrumbsImg})`
	}

	return (
		<div className="rs-breadcrumbs" style={breadcrumbsImgStyle}>
			<div className="container">
				<div className="breadcrumbs-inner text-center">
					<ul>
						<li>
							<Link to="/" className="active">{parentCategory ? parentCategory : 'Home'}</Link>
						</li>
						{pageCategory ?
							<>
								<li>
									<Link to={pageCategoryUrl ?? '/'} className="active">{pageCategory ? pageCategory : 'Category'}</Link>
								</li>
								<li>{pageName ? pageName : 'Page Name'}</li>
							</> : <li>{pageName ? pageName : 'Page Name'}</li>
						}

					</ul>
					<h1 className={titleClass ? titleClass : 'page-title'}>{pageTitle ? pageTitle : 'Breadcrumbs'}</h1>
					{/*<ul>*/}
					{/*	<li>*/}
					{/*		<Link to="/" className="active">{parentCategory ? parentCategory : 'Home'}</Link>*/}
					{/*	</li>*/}
					{/*	{pageCategory ? */}
					{/*		<>*/}
					{/*			<li>*/}
					{/*				<Link to={pageCategoryUrl ?? '/'} className="active">{pageCategory ? pageCategory : 'Category'}</Link>*/}
					{/*			</li> */}
					{/*			<li>{pageName ? pageName : 'Page Name'}</li>*/}
					{/*		</> : <li>{pageName ? pageName : 'Page Name'}</li>							*/}
					{/*	}*/}
					{/*	*/}
					{/*</ul>*/}
					{/*<p>We aim to help you connect with your clients through one of the most important and Widely spread technologies ... Apps.</p>*/}
					<h4 className='banner-description'>{pageDescription}</h4>
				</div>
			</div>
		</div>
	);
}

export default SiteBreadcrumb;




